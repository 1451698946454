<template>
	<!-- 异常预警 -->
	<div class="page-box">
		<div class="tab-box">
			<div class="tab-conent-box">
				<div class="tab-list-box">
					<ul>
						<li class="width-s sclect"><el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox></li>
						<li class="width-s">序号</li>
						<li class="width-xl">时间</li>
						<li class="width-l">仪器编号</li>
						<li class="width-xl">内容</li>
						<li class="width-m">操作</li>
					</ul>
				</div>
				<div class="scroll-list" v-show="item.total">
					<vue-scroll>
						<el-checkbox-group v-model="checkList" @change="handleCheckedChange">
							<ul class="table-list-ul" v-for="(i, index) in listData" :key="index">
								<li class="width-s sclect"><el-checkbox :label="i.id"></el-checkbox></li>
								<li class="width-s">{{ index + 1 }}</li>
								<li class="width-xl text-overflow">
									<span class="blue">{{ i.time | dataDetect }}</span>
								</li>
								<li class="width-l text-overflow">
									<!-- <span class="blue"> -->
									{{ i.deviceNum | dataDetect }}
									<!-- </span> -->
								</li>
								<li class="width-xl text-overflow">{{ i.content | dataDetect }}</li>
								<li class="width-m compile but">
									<i v-if="judgment('authorityList', 263, that)" class="el-icon-delete delete red" title="删除" @click="removeClick($event, i)"></i>
								</li>
							</ul>
						</el-checkbox-group>
					</vue-scroll>
				</div>
				<noData v-if="!item.total" />
			</div>
		</div>
		<div class="bottom-box" v-if="item.total">
			<el-button type="danger" v-if="judgment('authorityList', 263, that)" size="medium" plain @click="removeAll">删除</el-button>
			<div class="pagination-box">
				<div class="sizeBox">
					<span>单页显示条数</span>
					<input type="number" v-model="changeSize" autocomplete="off" />
				</div>

				<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'abnormal',
	props: {
		// 按钮权限
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			item: {
				rows: [], //列表数据
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'abnormal',
				dataType: 'list',
				text: '暂时没有数据哦--'
			},
			checkAll: false,
			changeSize: 30,
			checkList: [],
			that: null, //this对象
			type: null ,//当前数据匹配类型
			parameter: {},//列表请求参数
		};
	},
	created() {
		this.that = this;
	},
	mounted() {},
	computed: {
		listData() {
			var arr = this.item.rows.filter(item => {
				// if(this.type == null){
				// 	return item;
				// }
				// if(this.type == 0){
				// 	return item
				// }else{
				// 	if(item.type == this.type){
				// 		return item
				// 	}
				// }
				return item;
			});
			return arr;
		}
	},
	methods: {
		// 获取列表数据
		getList(val = {}) {
			this.checkAll = false;
			this.checkList = [];
			this.parameter = val;
			var data = {
				type: 2,
				page: this.item.page,
				size: this.item.size
			};
			if (val.hasOwnProperty('pushId')) {
				data.id = val.pushId;
			}
			if (val.hasOwnProperty('status')) {
				this.type = val.status;
			}
			if (val.hasOwnProperty('pointId')) {
				data.pointId = val.pointId;
			} else {
				data.pjid = this.$parent.parameter.id;
			}
			this.axios.post('web2/warn/odnr/galist', data).then(res => {
				if (res.status) {
					this.item.rows = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(this.item.total / this.item.size);
					let len = res.data.list.length;
					if(data.page != 1 && len == 0 && res.data.total != 0){
						this.item.page = data.page - 1;
						this.getList();
					}
				} else {
					this.item.rows = [];
					this.item.total = 0;
					this.item.allPage = 0;
				}
			});
		},
		// 删除报警
		removeAlarm(val) {
			var data = {
				ids: val,
				type: 2
			};
			this.axios.post('web2/warn/amd/dlart', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '删除成功',
						type: 'success'
					});
					// this.removeList(val);
					this.getList(this.parameter);
				}
			});
		},
		// 删除报警按钮点击事件
		removeClick(e, val) {
			this.$confirm('是否确定删除<strong>' + val.time + '</strong>的报警？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.removeAlarm(val.id);
				})
				.catch(() => {});
		},
		// 全部删除
		removeAll(){
			if (this.checkList.length == 0) {
				this.$message({
					showClose: true,
					message: '请至少选择一条数据',
					type: 'warning'
				});
				return;
			}
			this.$confirm('是否确定删除所有选中的报警？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.removeAlarm(this.checkList.toString());
				})
				.catch(() => {});
		},
		// 全选
		handleCheckAllChange(val) {
			this.checkList = [];
			if (val) {
				this.listData.forEach(i => {
					this.checkList.push(i.id);
				});
			}
		},
		// 多选框选择
		handleCheckedChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.listData.length;
		},
		// 类型选择重新赋值
		typeSelect(type) {
			this.type = type;
		},
		// 移除数据
		removeList(item) {
			var index = null;
			var len = this.item.rows.length;
			for (let i = 0; i < len; i++) {
				var val = this.item.rows[i];
				if (item.id == val.id) {
					index = i;
				}
			}
			if (index != null) {
				this.item.rows.splice(index, 1);
				this.calculate();
			}
		},
		// 重新计算分页
		calculate() {
			var len = this.item.rows.length;
			if (len == 0) {
				if (this.item.page > 1) {
					if (this.item.allPage == this.item.page) {
						this.item.page -= 1;
					}
				}
				this.getList(this.parameter);
			}
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			// // console.log(type)
			if (type === 'abnormal') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getList();
				// // console.log(123)
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.page-box {
	width: 100%;
	height: 100%;

	::v-deep {
		.el-checkbox__label {
			display: none;
		}
	}

	.tab-box {
		width: 100%;
		height: calc(100% - 110px);

		.tab-conent-box {
			width: 100%;
			height: 100%;

			ul {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				li {
					text-align: center;
				}

				.width-s {
					width: 8%;
				}

				.width-m {
					width: 15%;
				}

				.width-l {
					width: 20%;
				}

				.width-xl {
					width: 22%;
				}

				.gray-color {
					color: #909399;
				}
			}

			.tab-list-box {
				width: 100%;
				display: flex;
				align-items: center;
				// background-color: #87cbcb;
				background-color: #ebeef5;
				font-size: 15px;
				font-weight: 600;
				color: #4c4e51;
				letter-spacing: 1px;

				ul {
					height: 45px;
					line-height: 45px;
				}
			}

			.scroll-list {
				width: 100%;
				height: calc(100% - 45px);

				::v-deep {
					.__view {
						width: 100% !important;
					}
				}

				ul {
					// padding: 5px 0;
					border-bottom: thin solid #dcdfe6;

					li {
						height: 39px;
						line-height: 39px;
						font-size: 14px;
						color: #606266;
					}
				}

				.table-list-ul:nth-child(even) {
					background-color: #ffffff;
				}

				.table-list-ul:nth-child(odd) {
					background-color: #f2f2f2;
				}

				.table-list-ul:hover {
					background-color: #ebeef5;
				}

				.but {
					display: flex;
					justify-content: center;
					align-items: center;

					i {
						font-size: 20px;
						margin-left: 5px;
					}
					i:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.bottom-box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		width: calc(100% - 15px);
		height: 48px;
		padding-left: 15px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;
		// background-color: #f2f2f2;

		.pagination-box {
			height: fit-content;

			::v-deep {
				.pageNumber {
					margin: 0 5px;
				}

				.gotoPage {
					margin-top: 3px;
				}
			}

			.sizeBox {
				float: left;
				line-height: 30px;
				margin: 0px 10px 0 30px;

				input {
					width: 70px;
					text-indent: 0;
					text-align: center;
					margin: 0 5px;
					line-height: 24px;
					height: 24px;
					border-radius: 2px;
					border: 1px solid #e5e5e5;
				}
			}

			.tableFooter {
				width: auto;
				display: inline-block;
				position: absolute;
				float: left;
				background: none;
			}
		}
	}
}
</style>
