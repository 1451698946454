<template>
	<div class="page-box">
		<div class="tab-box">
			<noData v-if="!item.total" />
			<div class="tab-conent-box" v-show="item.total">
				<div class="tab-list-box">
					<ul>
						<li class="width-s">序号</li>
						<li class="width-l">测点编号</li>
						<li class="width-l">仪器编号</li>
						<li class="width-m">剩余空间</li>
						<li class="width-xl">时间</li>
						<li class="width-m">操作</li>
					</ul>
				</div>
				<div class="scroll-list">
					<vue-scroll>
						<ul v-for="(i, index) in item.rows" :key="index">
							<li class="width-s">{{index + 1}}</li>
							<li class="width-l text-overflow">{{i.name | dataDetect}}</li>
							<li class="width-l text-overflow">{{i.deviceNum | dataDetect}}</li>
							<li class="width-m text-overflow">{{i.surplus | dataDetect}}</li>
							<li class="width-xl text-overflow">{{i.time | dataDetect}}</li>
							<li class="width-m"><el-button v-show="i.release == 0" type="primary" size="mini" @click="removeAlarm($event,i)">解除</el-button></li>
						</ul>
					</vue-scroll>
				</div>
			</div>
		</div>
		<div class="bottom-box">
			<div class="sizeBox">
				<span>单页显示条数</span>
				<input type="number" v-model="changeSize" autocomplete="off" />
			</div>

			<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: 'storage',
	data() {
		return {
			item: {
				rows: [], //列表数据
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'storage',
				dataType: 'list',
				text: '暂时没有数据哦--'
			},
			changeSize: 30
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取列表数据
		getList(val = '') {
			var data = {
				pointId: this.$parent.parameter.id,
				type: 7,
				page: this.item.page,
				size: this.item.size
			};
			
			if(val != ''){
				data.id = val.id;
			}
			this.axios.post('monitor/alt/glist', data).then(res => {
				if (res.status) {
					this.item.rows = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / this.item.size);
				} else {
					this.item.rows = [];
					this.item.total = 0;
					this.item.allPage = 0;
				}
			});
		},
		// 解除报警 
		removeAlarm(e,val) {
			var data = {
				ids: val.id,
				type: 7
			};
			var obj = $(e.currentTarget);
			this.axios.post('monitor/alt/release', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '解除成功',
						type: 'success'
					});
					obj.hide();
				}
			});
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			// // console.log(type)
			if (type === 'storage') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getList();
				// // console.log(123)
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.page-box {
	width: 100%;
	height: 100%;
	
	.tab-box {
		width: 100%;
		height: calc(100% - 95px);

		.tab-conent-box {
			width: 100%;
			height: 100%;

			ul {
				width: 100%;
				height: 28px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 10px;
				margin-bottom: 10px;
				
				li{
					text-align: center;
				}
				
				.width-s{
					width: 8%;
				}
				
				.width-m{
					width: 15%;
				}
				
				.width-l{
					width: 20%;
				}
				
				.width-xl{
					width: 22%;
				}
			}

			.tab-list-box {
				width: 100%;
				height: 40px;
				display: flex;
				align-items: center;
				background-color: rgba(59, 158, 255, 0.2);
				color: #999;
			}

			.scroll-list {
				width: 100%;
				height: calc(100% - 45px);
			}
		}
	}

	.bottom-box {
		// display: flex;
		// justify-content: flex-start;
		// align-items: center;
		position: relative;
		width: 100%;
		height: 48px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;
		// background-color: #f2f2f2;

		.sizeBox {
			float: left;
			line-height: 30px;
			margin: 9px 10px 0 30px;

			input {
				width: 70px;
				text-indent: 0;
				text-align: center;
				margin: 0 5px;
				line-height: 24px;
				height: 24px;
				border-radius: 2px;
				border: 1px solid #e5e5e5;
			}
		}

		.tableFooter {
			width: auto;
			display: inline-block;
			position: absolute;
			float: left;
			background: none;
		}
	}
}
</style>
