<template>
	<!-- 超标预警 -->
	<div class="page-box overStandard-box">
		<div class="tab-box">
			<div class="tab-conent-box">
				<div class="tab-list-box">
					<ul>
						<li class="width-s sclect"><el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox></li>
						<li class="width-s" style="text-align: center;">序号</li>
						<li class="width-l" style="text-align: center;">时间</li>
						<li class="width-l">仪器编号</li>
						<li class="width-m">幅值（X）</li>
						<li class="width-m">幅值（Y）</li>
						<li class="width-m">幅值（Z）</li>
						<li class="width-m">速度</li>
						<!-- <li class="width-m">类型</li>
						<li class="width-l">说明</li> -->
						<li class="edit" style="text-align: center;">操作</li>
					</ul>
				</div>
				<div class="scroll-list" v-show="item.total">
					<vue-scroll>
						<el-checkbox-group v-model="checkList" @change="handleCheckedChange">
							<ul class="table-list-ul" v-for="(i, index) in listData" :key="index">
								<li class="width-s sclect"><el-checkbox :label="i.id"></el-checkbox></li>
								<li class="width-s" style="text-align: center;">{{ index + 1 }}</li>
								<li class="width-l text-overflow" style="text-align: center;">
									<span class="blue">{{ i.time | dataDetect }}</span>
								</li>
								<li class="width-l text-overflow">
									<!-- <span class="blue"> -->
									{{ i.deviceNum | dataDetect }}
									<!-- </span> -->
								</li>
								<li class="width-m text-overflow">{{ i.xmax ? i.xmax + i.unit : '-' }}</li>
								<li class="width-m text-overflow">{{ i.ymax ? i.ymax + i.unit : '-' }}</li>
								<li class="width-m text-overflow">{{ i.zmax ? i.zmax + i.unit : '-' }}</li>
								<li class="width-m text-overflow">{{ i.alert ? i.alert + i.unit : '-' }}</li>
								<!-- <li class="width-m text-overflow">
								<span class="blue" v-show="i.type == 0">正常</span>
								<span class="yellow" v-show="i.type == 2">预警</span>
								<span class="red" v-show="i.type == 1">报警</span>
							</li>
							<li class="width-l text-overflow" :title="i.content">{{i.content}}</li> -->
								<li class="edit compile but" style="text-align: center;">
									<i @click="openPush(i)" title="关联数据" class="icon iconfont icon-guanlian green pointer"></i>
									<i v-show="judgment('authorityList', 265, that)" class="el-icon-edit outline blue pointer" title="原因说明" @click="openEditor($event, i)"></i>
									<i v-show="!judgment('authorityList', 265, that)" class="el-icon-edit outline close pointer" title="原因说明"></i>
									<i v-show="judgment('authorityList', 264, that)" class="iconfont icon-jiechu i-yellow pointer" title="解除" @click="removeAlarm($event, i)"></i>
									<i v-show="judgment('authorityList', 263, that)" class="el-icon-delete delete red pointer" title="删除" @click="deleteClick($event, i)"></i>
									<i v-show="!judgment('authorityList', 263, that)" class="el-icon-delete delete close pointer" title="删除"></i>
								</li>
							</ul>
						</el-checkbox-group>
					</vue-scroll>
				</div>
				<noData v-if="!item.total" />
			</div>
		</div>
		<div class="bottom-box" v-if="item.total">
			<el-button type="danger" :disabled="!judgment('authorityList', 263, that)" size="medium" plain @click="deleteAll">删除</el-button>
			<div class="pagination-box">
				<div class="sizeBox">
					<span>单页显示条数</span>
					<input type="number" v-model="changeSize" autocomplete="off" />
				</div>

				<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
			</div>
		</div>
		<!-- 报警说明弹窗 -->
		<el-dialog title="报警说明" :visible.sync="dialogVisible" width="320px" top="16%" :modal-append-to-body="false">
			<div><el-input type="textarea" :rows="4" placeholder="请输入说明" v-model="instructions.content"></el-input></div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
				<el-button size="small" type="primary" @click="editorAlarm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'overStandard',
	props: {
		// 按钮权限
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			item: {
				rows: [], //列表数据
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'overStandard',
				dataType: 'list',
				text: '暂时没有数据哦--'
			},
			checkAll: false,
			checkList: [],
			changeSize: 30,
			that: null, //this对象
			instructions: {
				id: null,
				content: null // 内容
			}, // 报警说明
			dialogVisible: false, //报警说明弹窗
			type: null ,//当前数据匹配类型
			parameter: {},//列表请求参数
		};
	},
	created() {
		this.that = this;
	},
	mounted() {},
	computed: {
		listData() {
			var arr = this.item.rows.filter(item => {
				// if(this.type == null){
				// 	return item;
				// }
				// if(this.type == 0){
				// 	return item
				// }else{
				// 	if(item.type == this.type){
				// 		return item
				// 	}
				// }
				return item;
			});
			return arr;
		}
	},
	methods: {
		// 获取列表数据
		getList(val = {}) {
			this.checkAll = false;
			this.checkList = [];
			this.parameter = val;
			var data = {
				type: 1,
				page: this.item.page,
				size: this.item.size
			};

			if (val.hasOwnProperty('pushId')) {
				data.id = val.pushId;
			}
			if (val.hasOwnProperty('status')) {
				this.type = val.status;
			}
			if (val.hasOwnProperty('pointId')) {
				data.pointId = val.pointId;
			} else {
				data.pjid = this.$parent.parameter.id;
			}
			this.axios.post('web2/warn/odnr/galist', data).then(res => {
				if (res.status) {
					this.item.rows = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(this.item.total / this.item.size);
					let len = res.data.list.length;
					if(data.page != 1 && len == 0 && res.data.total != 0){
						this.item.page = data.page - 1;
						this.getList();
					}
				} else {
					this.item.rows = [];
					this.item.total = 0;
					this.item.allPage = 0;
				}
			});
		},
		// 解除报警
		removeAlarm(e, val) {
			if (e != null) {
				this.theSelected(e);
			}
			var data = {
				id: val.id
			};
			this.$confirm('是否解除<strong>' + val.time + '</strong>的报警?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/warn/amr/relieve', data).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: '解除除成功',
								type: 'success'
							});
							this.removeList(val);
						}
					});
				})
				.catch(() => {});
		},
		// 删除报警
		deleteClick(e, val) {
			this.$confirm('是否确定删除<strong>' + val.time + '</strong>的报警？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.deleteAlarm(val.id);
				})
				.catch(() => {});
		},
		// 删除报警
		deleteAlarm(val) {
			var data = {
				ids: val,
				type: 1
			};
			this.axios.post('web2/warn/amd/dlart', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '删除成功',
						type: 'success'
					});
					// this.removeList(val);
					this.getList(this.parameter);
				}
			});
		},
		// 全部删除
		deleteAll() {
			if (this.checkList.length == 0) {
				this.$message({
					showClose: true,
					message: '请至少选择一条数据',
					type: 'warning'
				});
				return;
			}
			this.$confirm('是否确定删除所有选中的报警？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.deleteAlarm(this.checkList.toString());
				})
				.catch(() => {});
		},
		// 编辑 mark
		editorAlarm() {
			this.axios.post('web2/warn/amk/mark', this.instructions).then(res => {
				if (res.status) {
					for (let key in this.instructions) {
						this.instructions[key] = null;
					}
					this.dialogVisible = false;
					this.getList();
				}
			});
		},
		// 全选
		handleCheckAllChange(val) {
			this.checkList = [];
			if (val) {
				this.listData.forEach(i => {
					this.checkList.push(i.id);
				});
			}
		},
		// 多选框选择
		handleCheckedChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.listData.length;
		},
		// 类型选择重新赋值
		typeSelect(type) {
			this.type = type;
		},
		// 打开编辑弹窗
		openEditor(e = null, i) {
			if (e != null) {
				this.theSelected(e);
			}
			this.instructions.id = i.id;
			this.instructions.content = i.content;
			this.dialogVisible = true;
		},
		// 移除数据
		removeList(item) {
			var index = null;
			var len = this.item.rows.length;
			for (let i = 0; i < len; i++) {
				var val = this.item.rows[i];
				if (item.id == val.id) {
					index = i;
				}
			}
			if (index != null) {
				this.item.rows.splice(index, 1);
				this.calculate();
			}
		},
		// 重新计算分页
		calculate(refresh = false) {
			var len = this.item.rows.length;
			if (len == 0) {
				if (this.item.page > 1) {
					if (this.item.allPage == this.item.page) {
						this.item.page -= 1;
					}
				}

				this.getList(this.parameter);
			}
		},
		// 打开关联数据列表内容
		openPush(i) {
			var data = {
				name: this.$parent.parameter.name,
				id: this.$parent.parameter.id,
				img: this.$parent.parameter.img,
				pid: i.pointId,
				fileTime: i.fileTime
			};
			this.toRouter('dataManagement', data, 'query');
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			// // console.log(type)
			if (type === 'overStandard') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getList();
				// // console.log(123)
			}
		},
		// 当前操作数据更改样式
		theSelected(e) {
			$('.overStandard-box .scroll-list .table-list-ul').removeClass('selected-data');
			$(e.currentTarget)
				.parent()
				.parent()
				.addClass('selected-data');
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.page-box {
	width: 100%;
	height: 100%;

	::v-deep {
		.el-checkbox__label {
			display: none;
		}
	}

	.tab-box {
		width: 100%;
		height: calc(100% - 110px);

		// 当前操作样式
		.selected-data {
			background-color: rgba($color: #c8c8c8, $alpha: 1) !important;
		}

		.tab-conent-box {
			width: 100%;
			height: 100%;

			ul {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				li {
					text-align: left;
				}

				.width-s {
					width: 8%;
				}

				.width-m {
					width: 15%;
				}

				.edit {
					width: 14.5%;
					flex-shrink: 0;
				}

				.width-l {
					width: 20%;
				}

				.width-xl {
					width: 22%;
				}

				.gray-color {
					color: #909399;
				}

				.yellow {
					color: #f0da10;
				}

				// .red{
				// 	color: #ff6b66;
				// }
			}

			.sclect {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.tab-list-box {
				width: 100%;
				display: flex;
				align-items: center;
				// background-color: #87cbcb;
				background-color: #ebeef5;
				font-size: 15px;
				font-weight: 600;
				color: #4c4e51;
				letter-spacing: 1px;

				ul {
					height: 45px;
					line-height: 45px;
				}
			}

			.scroll-list {
				width: 100%;
				height: calc(100% - 45px);

				::v-deep {
					.__view {
						width: 100% !important;
					}
				}

				ul {
					// padding: 5px 0;
					border-bottom: thin solid #dcdfe6;

					li {
						height: 39px;
						line-height: 39px;
						font-size: 14px;
						color: #606266;
					}
				}

				.table-list-ul:nth-child(even) {
					background-color: #ffffff;
				}

				.table-list-ul:nth-child(odd) {
					background-color: #f2f2f2;
				}
				.table-list-ul:hover {
					background-color: #ebeef5;
				}

				.but {
					display: flex;
					justify-content: center;
					align-items: center;

					i {
						font-size: 20px;
						margin-left: 5px;
					}
					i:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.bottom-box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		width: calc(100% - 15px);
		height: 48px;
		padding-left: 15px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;
		// background-color: #f2f2f2;

		.pagination-box {
			height: fit-content;

			::v-deep {
				.pageNumber {
					margin: 0 5px;
				}

				.gotoPage {
					margin-top: 3px;
				}
			}

			.sizeBox {
				float: left;
				line-height: 30px;
				margin: 0px 10px 0 30px;

				input {
					width: 70px;
					text-indent: 0;
					text-align: center;
					margin: 0 5px;
					line-height: 24px;
					height: 24px;
					border-radius: 2px;
					border: 1px solid #e5e5e5;
				}
			}

			.tableFooter {
				width: auto;
				display: inline-block;
				position: absolute;
				float: left;
				background: none;
			}
		}
	}
}
</style>
