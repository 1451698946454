<template>
	<div class="alarm-box">
		<div class="top-box">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				<el-menu-item index="1">超标预警</el-menu-item>
				<el-menu-item index="2">异常预警</el-menu-item>
				<!-- <el-menu-item index="3">离线异常</el-menu-item> -->
				<!-- <el-menu-item index="4">电量异常</el-menu-item> -->
				<!-- <el-menu-item index="5">电压异常</el-menu-item> -->
				<!-- <el-menu-item index="6">供电异常</el-menu-item> -->
				<!-- <el-menu-item index="7">存储异常</el-menu-item> -->
			</el-menu>
			<!-- <div class="right">
				<el-select v-model="type" placeholder="请选择报警类型" @change="selectType">
					<el-option v-for="(item, index) in typeOptions" :key="index" :label="item.name" :value="item.value"></el-option>
				</el-select>
			</div> -->
		</div>

		<!-- 超标 -->
		<over-standard ref="over_standard" v-show="activeIndex == '1'" :authorityList="authorityList"></over-standard>
		<!-- 数据异常 -->
		<data-abnormal ref="data_abnormal" v-show="activeIndex == '2'" :authorityList="authorityList"></data-abnormal>
		<!-- 离线 -->
		<!-- <off-line ref="off_line" v-show="activeIndex == '3'"></off-line> -->
		<!-- 电量 -->
		<!-- <electricity ref="electricity" v-show="activeIndex == '4'"></electricity> -->
		<!-- 电压 -->
		<!-- <voltage ref="voltage" v-show="activeIndex == '5'"></voltage> -->
		<!-- 供电 -->
		<!-- <power-supply ref="power_supply" v-show="activeIndex == '6'"></power-supply> -->
		<!-- 存储 -->
		<!-- <storage ref="storage" v-show="activeIndex == '7'"></storage> -->
	</div>
</template>

<script>
import dataAbnormal from './components/dataAbnormal.vue';
import overStandard from './components/overStandard.vue';
import electricity from './components/electricity.vue';
import offLine from './components/offLine.vue';
import powerSupply from './components/powerSupply.vue';
import storage from './components/storage.vue';
import voltage from './components/voltage.vue';

export default {
	name: 'alarm',
	data() {
		return {
			activeIndex: '1',
			parameter: {}, // 传递过来的参数
			sendParameters: {}, // 发送参数
			userList: [], //所有用户
			userSelect: [], //选择的用户
			type: null, //数据类型
			typeOptions: [
				{
					value: 0,
					name: '全部'
				},
				{
					value: 1,
					name: '报警'
				},
				{
					value: 2,
					name: '预警'
				}
			], //数据类型选择数据
			authorityList: [], //权限
			ops: {
				bar: {
					background: 'rgba(144,147,153,0.7)'
				}
			}
		};
	},
	created() {},
	mounted() {
		var parameter = this.$route.query;
		for (let key in parameter) {
			this.parameter[key] = parameter[key];
		}
		if (this.parameter.hasOwnProperty('id')) {
			// if (this.parameter.hasOwnProperty('status')) {
			// 	this.type = this.parameter.status;
			// }
			this.$refs.over_standard.getList(this.parameter);
		} else {
			this.toRouter('index');
		}
		this.getPermissions();
	},
	computed: {},
	methods: {
		// 获取页面权限
		getPermissions() {
			this.authorityList = [];
			this.axios.get('web2/warn/odnr/gact').then(res => {
				if (res.status) {
					this.authorityList = res.data;
				}
			});
		},
		// 获取用户列表
		getUserList() {
			this.axios.get('/wt/user').then(res => {
				this.userList = [];
				if (res.status) {
					for (let key in res.data) {
						res.data[key].forEach(item => {
							item.checked = false;
							item.indeterminate = false;
							this.userList.push(item);
						});
					}
				} else {
					this.userList = [];
				}
				$('.user-mask').show();
			});
		},
		// 类型选择
		selectType(val) {
			if (this.activeIndex == '1') {
				this.$refs.over_standard.typeSelect(val);
			} else if (this.activeIndex == '2') {
				this.$refs.data_abnormal.typeSelect(val);
			}
		},
		// 直接全选
		handleCheckAllChange(val, data) {
			data.indeterminate = false;
			if (data.hasOwnProperty('id')) {
				var index = this.userSelect.indexOf(data.id);

				if (val) {
					if (index == -1) {
						this.userSelect.push(data.id);
					}
				} else {
					if (index != -1) {
						this.userSelect.splice(index, 1);
					}
				}
			} else {
				data.checked = val;
				data.indeterminate = false;
				data.list.forEach(item => {
					var index = this.userSelect.indexOf(item.id);
					if (val) {
						if (index == -1) {
							this.userSelect.push(item.id);
						}
					} else {
						if (index != -1) {
							this.userSelect.splice(index, 1);
						}
					}
				});
			}
		},
		// 多选框选择
		handleCheckedCitiesChange(val, data) {
			var len = data.list.length;
			var lens = 0;
			data.list.forEach(item => {
				if (this.userSelect.indexOf(item.id) != -1) {
					lens = lens + 1;
				}
			});
			if (lens == len) {
				data.checked = true;
				data.indeterminate = false;
			} else if (lens > 0 && lens != len) {
				data.indeterminate = true;
				data.checked = false;
			} else if (lens == 0) {
				data.checked = false;
				data.indeterminate = false;
			}
		},
		handleSelect(key, keyPath) {
			// // console.log(key, keyPath);
			this.type = '';
			this.activeIndex = keyPath[0];
			if (key == 1) {
				this.$refs.over_standard.getList(this.parameter);
			} else if (key == 2) {
				this.$refs.data_abnormal.getList(this.parameter);
			} else if (key == 3) {
				this.$refs.off_line.getList();
			} else if (key == 4) {
				this.$refs.electricity.getList();
			} else if (key == 5) {
				this.$refs.voltage.getList();
			} else if (key == 6) {
				this.$refs.power_supply.getList();
			} else if (key == 7) {
				this.$refs.storage.getList();
			}
		}
	},
	components: {
		dataAbnormal,
		overStandard,
		electricity,
		offLine,
		powerSupply,
		storage,
		voltage
	},
	beforeRouteUpdate(to, from, next) {
		// // console.log('更新');
		// // console.log(to)
		for (let key in to.query) {
			this.parameter[key] = to.query[key];
		}
		this.$refs.over_standard.getList(this.parameter);
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.alarm-box {
	width: 100%;
	height: 100%;
	// color: #ffffff;

	::v-deep {
		.el-menu {
			padding-left: 15px;
		}

		.el-menu-demo .el-menu-item {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
		}

		.el-menu.el-menu--horizontal {
			border-bottom: none;
		}
	}

	.top-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
</style>
